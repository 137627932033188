h2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

h2:after,
h2:before {
  content: "";
  width: 400px;
  height: 1px;
  background: grey;
  opacity: 0.4;
  margin: 0 10px;
}
